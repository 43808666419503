<template>
  <div class="g10-tender-stat">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="reportDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyyMMdd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleExport">导出Excel</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import g10Service from '@/services/g10Service'
import auth from '@/common/auth'
import MySpread from '@/components/Spread/MySpread'
import MySearchBar from '@/components/MySearchBar/MySearchBar'

export default {
  name: 'G10TenderStat',
  components: {
    MySearchBar,
    MySpread
  },
  data () {
    return {
      spreadInfo: {
        sheetName: '工程材料进度年计划汇总表',
        columns: [
          {
            label: '序号',
            dataKey: 'materialListCode',
            width: '50'
          },
          {
            label: '材料类别',
            dataKey: 'parentMaterialListName',
            width: '120',
            autoMerge: true
          },
          {
            label: '材料名称',
            dataKey: 'materialListName',
            width: 200
          },
          {
            label: '单位',
            dataKey: 'unit',
            width: 90
          },
          {
            label: '合同总用量',
            dataKey: 'num',
            width: 120
          },
          {
            label: '本年度计划用量',
            dataKey: 'currentYearNum',
            width: 120
          },
          {
            label: '1月',
            dataKey: 'month1Num',
            width: 90
          },
          {
            label: '2月',
            dataKey: 'month2Num',
            width: 90
          },
          {
            label: '3月',
            dataKey: 'month3Num',
            width: 90
          },
          {
            label: '4月',
            dataKey: 'month4Num',
            width: 90
          },
          {
            label: '5月',
            dataKey: 'month5Num',
            width: 90
          },
          {
            label: '6月',
            dataKey: 'month6Num',
            width: 90
          },
          {
            label: '7月',
            dataKey: 'month7Num',
            width: 90
          },
          {
            label: '8月',
            dataKey: 'month8Num',
            width: 90
          },
          {
            label: '9月',
            dataKey: 'month9Num',
            width: 90
          },
          {
            label: '10月',
            dataKey: 'month10Num',
            width: 90
          },
          {
            label: '11月',
            dataKey: 'month11Num',
            width: 90
          },
          {
            label: '12月',
            dataKey: 'month12Num',
            width: 90
          }
        ]
      },
      defaultDataList: [],
      engineeringList: [],
      tenderStatList: [],
      engineeringMap: {},
      otherTenderStats: [],
      reportDate: '',
      engineeringType: this.$route.query.engineeringType || '土建'
    }
  },
  watch: {
    reportDate (newVal) {
      this.getData()
    }
  },
  methods: {
    handleExport () {
      this.$refs.mySpread.exportExcel('标段工程材料进度年计划汇总表.xlsx')
    },
    generateMapData () {
      this.defaultDataList = this.tenderStatList.map((item, index) => {
        item.index = index
        return item
      })

      this.$loading().close()
    },
    getData () {
      this.$myLoading()
      this.tenderStatList = []
      this.defaultDataList = []
      if (!this.reportDate) {
        this.$loading().close()
        return
      }
      const year = parseInt(this.reportDate.substr(0, 4))
      g10Service.getTenderStat(year, auth.getUserInfo().projectGuid, this.engineeringType)
        .then(res => {
          if (res.data.code === 1) {
            this.tenderStatList = res.data.data

            this.generateMapData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败，请重试！'
          })
        })
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.g10-tender-stat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
